import Jsona from "jsona";
import ApiService from "@/common/api.service";
import { format } from "date-fns";

export const state = { 
    invoice: {},
    invoices: {},
    statuses: [
        'Sent',
        'Delivered',
        'Delinquent',
        'Paid',
        'Write-Off Debt',
        'Write-Off Review',
        'Sent To Collections'
    ]
};

export const actions = {
    fetch(context, payload) {
        return ApiService.get('/client-invoices', {
            include: ['clientAccount'],
            'fields[client-invoices]': 'id,invoice_date,invoice_number,status,total_amount_paid,total_amount_credit_memo,total_amount_due,due_at,invoice_document_url',
            'fields[billing-groups]': 'id,name',
            'filter[search]': payload.search || '',
            'filter[begin_date]': payload.begin_date || format(new Date(2000, 0, 1), 'yyyy-MM-dd'),
            'filter[end_date]': payload.end_date || format(new Date(), 'yyyy-MM-dd'),
            'filter[location_ids]': payload.location_ids || '',
            'filter[statuses]': payload.statuses || 'Sent,Delivered,Delinquent,Paid,Write-Off Debt,Write-Off Review,Sent To Collections',
            page: payload.page || '',
            sort_by: payload.sort_by || 'invoice_date',
            sort_direction: payload.sort_direction || 'desc'
        })
        .then((response) => { context.commit('SET_INVOICES', response.data); })
    },

    fetchOne(context, payload) {
        return ApiService.get(`/client-invoices/${payload.id}`)
            .then((response) => { context.commit('SET_INVOICE', response.data); })
    },

    fetchLocations(context, payload = {}) {
        return ApiService.get('/locations', {
            'fields[locations]': 'id,name,address,metadata',
            per_page: payload.per_page || '9999',
            sort_by: payload.sort_by || 'name',
        })
        .then((response) => { context.commit('SET_LOCATIONS', response.data); })
    },
};

export const mutations = {
    SET_INVOICES(state, invoices) {
        state.invoices = invoices;
    },
    SET_INVOICE(state, invoice) {
        state.invoice = invoice;
    },
    SET_LOCATIONS(state, locations) {
        state.locations = locations;
    }
};

export const getters = {
    all(state) {
        const dataFormatter = new Jsona();
        return dataFormatter.deserialize(state.invoices);
    },

    meta(state) {
        return state.invoices.meta;
    },

    locations(state) {
        const dataFormatter = new Jsona();
        return dataFormatter.deserialize(state.locations);
    },

    statuses(state) {
        return state.statuses;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
